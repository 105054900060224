import axios from 'axios'

import { onRequest, onRequestError, onResponse, onResponseError } from './interceptors'

// Use direct URL if its a prod, otherwise, use proxy.
const baseURL = process.env.REACT_APP_ENVIRONMENT === 'prod' ? process.env.REACT_APP_BACKEND_URL : '/api'
const api = axios.create({ baseURL: baseURL })

// Add a request interceptor
api.interceptors.request.use(onRequest, onRequestError)

// Add a response interceptor
api.interceptors.response.use(onResponse, onResponseError)

export const API_LOGIN = 'user/login?_format=json'
export const API_EVENTS = 'list/events-and-meetings?_format=json'
export const API_EVENT_DETAILS = 'events-and-meetings/detail?_format=json'
export const API_NEWS = 'list/news?_format=json'
export const API_NEWS_DETAILS = 'news/detail?_format=json'
export const API_FORGOT_PASSWORD = 'user/forgot-password?_format=json&type=mobile'
export const API_REGISTER = 'user/signup?_format=json&type=mobile'
export const API_MEMBERSHIP_CATEGORY = 'get/ipu_membership_category?_format=json'
export const API_RESET_PASSWORD = 'user/reset-password-by-link?_format=json&type=mobile'
export const PROFILE_DETAILS = 'profile/details/profile?_format=json'
export const API_VIEW_PROFILE = 'user/view-profile?_format=json'
export const API_UPDATE_PROFILE = 'user/edit-profile?_format=json'
export const API_CHANGE_PASSWORD = 'user/change-password?_format=json'
export const API_DELETE_ACCOUNT = 'delete-account?_format=json'
export const API_FETCH_FAQ = 'list/faqs?_format=json'
export const API_ARTICLES = 'list/articles?_format=json'
export const API_ARTICLES_DETAILS = 'article/detail?_format=json'
export const API_ALERTS = 'alerts/list?_format=json&page={pageNumber}&count={countNumber}&search_key={searchKey}'
export const API_ALERTS_UPDATE = 'alerts/update?_format=json'
export const API_MESSAGE_DETAILS = 'message/detail/{id}'
export const API_HLAG = 'hlag/content?_format=json'
export const API_POSITION_GROUP = 'positions-in-group/list'
export const API_POSITION_PARLIAMENT = 'positions-in-parliament/list'
export const API_GEO_GROUPS = 'geopolitical-groups/list'
export const API_HLAG_USER = 'hlag-user/detail?email={emailId}&_format=json'
export const STATIC_SCREEN = 'static-screen/{api}?_format=json'
export const API_FIREBASE_TOKEN = 'alert/push-token'
export const API_FIREBASE_LOGOUT = 'alert/remove-token?_format=json'
export const LANDING_PAGE = 'landing-page?_format=json'
export const LANDING_LOGGED_PAGE = 'home-page?_format=json'

export default api
