import { createSlice } from '@reduxjs/toolkit'
import reduxStore from '../store'

import api, { API_ALERTS, API_ALERTS_UPDATE, API_FIREBASE_TOKEN } from '../utils/axios'
import { getLocalStore, setLocalStore, setResponse, setSearch } from '../utils/utils'
import { setDetails } from './session.slice'

const initialState = {
  loading: false,
  alerts: [],
  infinteLoading: false,
  elements_count: 0,
  refreshRequired: false
}

const alerts = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setAlerts: (state, action) => {
      state.alerts = action.payload.alerts
    },
    setInfiniteLoading: (state, action) => {
      state.infinteLoading = action.payload.infinteLoading
    },
    setCount: (state, action) => {
      state.elements_count = action.payload.elements_count
    },
    setRefreshAlertsState: (state, action) => {
      state.refreshRequired = action.payload
    }
  }
})

export const { setLoading, setAlerts, setCount, setInfiniteLoading, setRefreshAlertsState } = alerts.actions
export default alerts.reducer

export const fetchAlerts = ({ data, callback }) => async (dispatch) => {
  try {
    setSearch('alerts_Search', {
      search_key: data?.search_key
    })
    const { getState } = reduxStore
    data?.page === 1 ? dispatch(setLoading({ loading: true })) : dispatch(setInfiniteLoading({ infinteLoading: true }))

    const response = await api.get(API_ALERTS.replace('{searchKey}', data.search_key || '').replace('{pageNumber}', data.page).replace('{countNumber}', data.count))
    if (response) {
      data?.page === 1 ? dispatch(setAlerts({ alerts: response.data?.alerts })) : dispatch(setAlerts({ alerts: [...getState()?.alerts?.alerts, ...response.data?.alerts] }))
      dispatch(setCount({ elements_count: response?.data?.elements_count }))
      data?.page === 1 ? dispatch(setLoading({ loading: false })) : dispatch(setInfiniteLoading({ infinteLoading: false }))
      callback && callback()
      setResponse('alerts', JSON.stringify(getState()?.alerts))
      setResponse('alertlang', JSON.stringify(getLocalStore('i18nextLng')))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setInfiniteLoading({ infinteLoading: false }))
  }
}

export const readAlert = (data) => async (dispatch) => {
  try {
    const response = await api.post(API_ALERTS_UPDATE, data)
    if (response) {
      const profileDetails = getLocalStore('PROFILE_DETAILS')
      if (profileDetails.count_unread_alerts > 0) {
        profileDetails.count_unread_alerts = profileDetails?.count_unread_alerts - 1
      }
      setLocalStore('PROFILE_DETAILS', profileDetails)
      dispatch(setDetails({ details: profileDetails }))
      dispatch(fetchAlerts({
        data: {
          search_key: '',
          page: 1,
          count: 10
        }
      }))
    }
  } catch (error) {

  }
}

export const sendFirebaseToken = async (data) => {
  try {
    await api.post(API_FIREBASE_TOKEN, data)
  } catch (error) {}
}
