import EventsImg from '../../assets/tab-bar-events.png'
import AlertsImg from '../../assets/tab-bar-alerts.png'
import ArticlesImg from '../../assets/tab-bar-articles.png'
import FaqImg from '../../assets/tab-bar-faq.png'
import NewsImg from '../../assets/tab-bar-news.png'
import EventsImgActive from '../../assets/tab-bar-events-active.png'
import ArticlesImgActive from '../../assets/tab-bar-articles-active.png'
import FaqImgActive from '../../assets/tab-bar-faq-active.png'
import NewsImgActive from '../../assets/tab-bar-news-active.png'
import AlertsImgActive from '../../assets/tab-bar-alerts-active.png'

const footer = [
  {
    icon: EventsImg,
    label: 'footer.events',
    route: '/events',
    active: EventsImgActive,
    details: 'eventsdetails'
  },
  {
    icon: NewsImg,
    label: 'footer.news',
    route: '/news',
    active: NewsImgActive,
    details: 'newsdetails'
  },
  {
    icon: ArticlesImg,
    label: 'footer.articles',
    route: '/articles',
    active: ArticlesImgActive,
    details: 'articlesdetails'
  },
  {
    icon: FaqImg,
    label: 'footer.faq',
    route: '/faq',
    active: FaqImgActive,
    details: ''
  },
  {
    icon: AlertsImg,
    label: 'footer.alerts',
    route: '/alerts',
    active: AlertsImgActive,
    details: ''
  }
]
export default footer
