import styled from 'styled-components'

export const Wrapper = styled.div`
${({ noFilterIcon, noHeader, theme, noSearchMenuIcon, headerImage }) => `
// height: ${noSearchMenuIcon ? '80px' : ''};
display:${noHeader ? 'none' : 'flex'};
position:relative;
${headerImage
  ? `height:140px;
    background-image: url(${process.env.REACT_APP_ENVIRONMENT === 'prod' ? `${process.env.REACT_APP_BACKEND_URL}${headerImage}` : `/api${headerImage}`});
    background-repeat: no-repeat;
    background-size: cover;`
  : `background-color: ${theme.colors.primaryMain}`};
}
h2 {
    font-size: ${theme.fontSizes.name.large};
    font-weight: bold;
    color: ${theme.colors.white};
    padding-left: 60px;
    flex: 0 0 65%;
}
#searchIcon {
    height:20px;
    width:20px;
    position:absolute;
    right:${noFilterIcon ? '20px' : '60px'};
    top: 35%;
    cursor:pointer;
    z-index: 99;
}
#filterIcon {
    height:20px;
    width:20px;
    position:absolute;
    right:20px;
    top: 35%;
    cursor:pointer;
    z-index: 99;
}
.MuiSvgIcon-root {
  position: absolute;
  top: 23px;
  fill: white;
  left: 18px;
}
#menuIcon {
  height:16px;
  width:22px;
  position:absolute;
  left:0;
  padding-left: 20px;
  top: 38%;
  z-index: 99;
}
#backIcon{
  height: 22px;
  width: 24px;
  cursor: pointer;
  padding-top: 25px;
  padding-left: 20px;
  padding-bottom: 10px;
  z-index: 99;
}
`}
`
