import styled from 'styled-components'
import Box from '@material-ui/core/Box'

const ButtonContainer = styled(Box)`
  ${({ theme }) => `
.MuiButton-root {
  width: 100%;
  height: 50px;
  font-size: ${theme.fontSizes.large};
  background: 
    ${theme.colors.primaryMain};
  text-transform: none;
  white-space: nowrap;
  color: ${theme.colors.white};
  border-radius: 0px;
  padding-left: 30px;
  padding-right: 30px; 
  cursor: pointer; 
  border-radius: 10px;
  padding: 0;
}

text-transform: none !important;
color: white;
border-radius: 0px;

.MuiCircularProgress-colorPrimary {
  color: ${theme.colors.white};
}
.MuiButton-root:hover {
  background-color:${theme.colors.primaryMain} !important;
}
`}
`

export default ButtonContainer
