import { createSlice } from '@reduxjs/toolkit'

import api, { API_HLAG, API_HLAG_USER } from '../utils/axios'

const initialState = {
  loading: false,
  hlagDetails: {},
  hlagUserDetails: {}
}

const hlag = createSlice({
  name: 'hlag',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setHlagDetails: (state, action) => {
      state.hlagDetails = action.payload.hlagDetails
    },
    setHlagUserDetails: (state, action) => {
      state.hlagUserDetails = action.payload.data
    },
    clearHlagUserDetails: (state, action) => {
      state.hlagUserDetails = initialState.hlagDetails
    }
  }
})

export const { setLoading, setHlagDetails, setHlagUserDetails, clearHlagUserDetails } = hlag.actions
export default hlag.reducer

export const fetchHlagDetails = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(API_HLAG)
    if (response) {
      dispatch(setHlagDetails({ hlagDetails: response.data }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const fetchHlagUserDetails = (emailId) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(API_HLAG_USER.replace('{emailId}', encodeURIComponent(emailId)))

    if (response) {
      dispatch(setHlagUserDetails({ data: response.data?.hlag_user_details }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}
