const hamburgerMenu = [
  {
    label: 'hamburger.hlag',
    route: '/hlag',
    api: 'hlag'
  },
  {
    label: 'hamburger.globparNet',
    route: '/static',
    api: 'global_parliamentary_network'
  },
  {
    label: 'hamburger.intLeg',
    route: '/static',
    api: 'international_legislations'
  },
  {
    label: 'hamburger.unodc',
    route: '/static',
    api: 'unodc_unoct'
  },
  {
    label: 'hamburger.ipu',
    route: '/static',
    api: 'inter_parliamentary_union'
  },
  {
    label: 'hamburger.logout',
    route: '',
    api: 'logout'
  }
]
export default hamburgerMenu
