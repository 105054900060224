import styled from 'styled-components'

export const Wrapper = styled.div`
${({ theme }) => `
img  {
    width: 15px;
    height: 18px;
}        
.MuiBottomNavigationAction-root.Mui-selected {
    color: ${theme.colors.lightBlue};
}     
.MuiBottomNavigationAction-label {
    font-size: ${theme.fontSizes.footer.label} !important;
}             
.MuiBottomNavigation-root {
    button:first-child {
        padding-left: 5px;
        padding-top: 15px;
    .MuiBottomNavigationAction-label {
     width: 46px;
     word-break: break-word;
     padding-top: 6px;
        }
    }
    box-shadow: ${theme.boxShadows.footer};
}      

.MuiBottomNavigation-root {
    button:not(:first-child) {
        .MuiBottomNavigationAction-wrapper {
            img {
                padding-bottom:22px;
            }
        }
        .MuiBottomNavigationAction-label {
            position: fixed;
            bottom: 23px;
        }
    }
}
.MuiBadge-badge {
    height: 15px;
    min-width: 15px;
    font-weight: normal;
    font-size: ${theme.fontSizes.footer.badge};
    padding:0px;
}
`}
`
