import { createSlice } from '@reduxjs/toolkit'

import { setLocalStore, deleteItem } from '../utils/utils'

import api, { API_LOGIN, PROFILE_DETAILS, API_FIREBASE_LOGOUT } from '../utils/axios'
import { sendFirebaseToken } from './alerts.slice'

const initialState = {
  loading: false,
  isLoggedIn: false,
  user: {},
  logoutLoader: false
}

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn
    },
    setUser: (state, action) => {
      state.user = action.payload.user
    },
    setDetails: (state, action) => {
      state.details = action.payload.details
    },
    setLoadingLogout: (state, action) => {
      state.logoutLoader = action.payload.logoutLoader
    }
  }
})

export const { setLoading, setLoggedIn, setUser, setDetails, setLoadingLogout } = session.actions
export default session.reducer

export const login = ({ data, callback }) => async (dispatch) => {
  try {
    const firebaseToken = window.localStorage.getItem('firebaseToken')
    dispatch(setLoading({ loading: true }))
    dispatch(setLoggedIn({ isLoggedIn: false }))
    const response = await api.post(API_LOGIN, data)
    if (response) {
      dispatch(setLoggedIn({ isLoggedIn: false }))
      setLocalStore('AUTH_DETAILS_MOBILE', response.data)
      dispatch(setLoading({ loading: false }))
      firebaseToken && sendFirebaseToken({ token: firebaseToken })
      dispatch(profilePic())
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const profilePic = () => async (dispatch) => {
  const response = await api.get(PROFILE_DETAILS)
  if (response) {
    dispatch(setDetails({ details: response?.data }))
    response.data && setLocalStore('PROFILE_DETAILS', response?.data)
    response.data?.image ? setLocalStore('PROFILE_IMAGE', response.data?.image) : deleteItem('PROFILE_IMAGE')
  }
}

export const logout = ({ data }) => (dispatch) => {
  return api.post(API_FIREBASE_LOGOUT, data)
}
