import { createSlice } from '@reduxjs/toolkit'

import api, { API_MESSAGE_DETAILS } from '../utils/axios'

const initialState = {
  loading: false,
  messageDetails: {}
}

const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setMessageDetails: (state, action) => {
      state.messageDetails = action.payload.messageDetails
    }
  }
})

export const { setLoading, setMessageDetails } = message.actions
export default message.reducer

export const fetchMessageDetails = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(API_MESSAGE_DETAILS.replace('{id}', data), {})
    if (response) {
      dispatch(setMessageDetails({ messageDetails: response.data }))
      dispatch(setLoading({ loading: false }))
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const clearStoreMsg = () => async (dispatch) => {
  dispatch(setMessageDetails({ messageDetails: {} }))
}
