import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

#root {
  height: 100%;
}
font-family: 'Roboto', sans-serif;
// font styles and configuration
`;
export default GlobalStyle;
