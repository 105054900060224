import { createSlice } from '@reduxjs/toolkit'
import reduxStore from '../store'

import api, { API_EVENTS, API_EVENT_DETAILS } from '../utils/axios'
import { setResponse, getLocalStore, setSearch } from '../utils/utils'

const initialState = {
  loading: false,
  events: [],
  upcoming_events: [],
  infinteLoading: false,
  elements_count: 0
}

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setEvents: (state, action) => {
      state.events = action.payload.events
    },
    setUpcomingEvents: (state, action) => {
      state.upcoming_events = action.payload.upcoming_events
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload.eventDetails
    },
    setInfiniteLoading: (state, action) => {
      state.infinteLoading = action.payload.infinteLoading
    },
    setCount: (state, action) => {
      state.elements_count = action.payload.elements_count
    }
  }
})

export const { setLoading, setEvents, setEventDetails, setUpcomingEvents, setInfiniteLoading, setCount } = events.actions
export default events.reducer

export const fetchEvents = ({ data, callback }) => async (dispatch) => {
  try {
    setSearch('events_Search', {
      search_key: data?.search_key,
      on_date_filter: data?.on_date_filter
    })
    const { getState } = reduxStore
    data?.page === 1 ? dispatch(setLoading({ loading: true })) : dispatch(setInfiniteLoading({ infinteLoading: true }))

    const response = await api.post(API_EVENTS, data)
    if (response) {
      data?.page === 1 ? dispatch(setEvents({ events: response.data?.events })) : dispatch(setEvents({ events: [...getState()?.events?.events, ...response.data?.events] }))
      data?.on_date_filter || data?.search_key ? dispatch(setUpcomingEvents({ upcoming_events: [] })) : dispatch(setUpcomingEvents({ upcoming_events: response.data?.upcoming_events }))
      dispatch(setCount({ elements_count: response?.data?.elements_count }))
      data?.page === 1 ? dispatch(setLoading({ loading: false })) : dispatch(setInfiniteLoading({ infinteLoading: false }))
      callback && callback()
      setResponse('events', JSON.stringify(getState()?.events))
      setResponse('eventlang', JSON.stringify(getLocalStore('i18nextLng')))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setInfiniteLoading({ infinteLoading: false }))
  }
}

export const fetchEventDetails = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_EVENT_DETAILS, data)
    if (response) {
      dispatch(setEventDetails({ eventDetails: response.data }))
      dispatch(setLoading({ loading: false }))
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const clearStoreEvent = () => async (dispatch) => {
  dispatch(setEventDetails({ eventDetails: {} }))
}
