import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getLocalStore } from './utils/utils'
import Styled from './privateStyle'
import Footer from './components/Footer'
import Header from './components/Header'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  const [calenderOpen, setCalenderOpen] = useState(false)
  const [langChange, setLangChange] = useState()
  const [headerImage, setHeaderImage] = useState('')
  const openSearch = (e) => {
    setCalenderOpen(e)
  }
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to / page
    <Route
      {...rest}
      render={(props) =>
        auth
          ? (
            <Styled.Wrapper>
              <Styled.Main>
                <Header openSearch={(e) => openSearch(e)} setLangChange={setLangChange} headerImage={headerImage} />
                <Styled.Content>
                  <Component {...props} calenderOpen={calenderOpen} setCalenderOpen={setCalenderOpen} langChange={langChange} setHeaderImage={setHeaderImage} />
                </Styled.Content>
              </Styled.Main>
              <Styled.FooterWrapper>
                <Footer />
              </Styled.FooterWrapper>
            </Styled.Wrapper>
            )
          : (
            <Redirect to='/' />
            )}
    />
  )
}

export default PrivateRoute
