import styled from 'styled-components'

const MainWrapper = styled.div`
  ${({ countrySelector, style, theme, mode, width, height }) => `

  width: 100%;
  .css-1okebmr-indicatorSeparator {
    display:none;
  }
  .css-6q0nyr-Svg {
    fill: black;
    opacity: ${countrySelector ? '0.5' : '1'};
  }
  .css-4miudc-control {
    background-color: transparent;
  }
  img {
    position: relative;
    top: 1px;
}
}
.select__control--menu-is-open .select__dropdown-indicator svg {
  transform: rotate(180deg);
}
.select__control--menu-is-open {
  border: ${theme.border.selectControl};
}
.select__control--is-focused {
  border: ${theme.border.selectControl};
}
.select__control--menu-is-open:hover {
  border: ${theme.border.selectControl};
}
.select__control--is-focused:hover {
  border: ${theme.border.selectControl};
}
.select__option--is-selected{
  background-color: ${theme.colors.lightBlue};
} 
.select__control--menu-is-open .select__placeholder{
  color: ${theme.colors.lightBlue};
}
.select__value-container {
  padding: ${countrySelector ? '2px 8px' : '11px 8px'};
}
.select__menu {
  z-index:99;
}
  `}
`

const Styled = { MainWrapper }
export default Styled
