import { createSlice } from '@reduxjs/toolkit'
import reduxStore from '../store'

import api, { API_NEWS, API_NEWS_DETAILS } from '../utils/axios'
import { setResponse, getLocalStore, setSearch } from '../utils/utils'

const initialState = {
  loading: false,
  newsDetails: {},
  news: [],
  trending_news: [],
  infinteLoading: false,
  elements_count: 0
}

const news = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setNews: (state, action) => {
      state.news = action.payload.news
    },
    setNewsDetails: (state, action) => {
      state.newsDetails = action.payload.newsDetails
    },
    setUpcomingNews: (state, action) => {
      state.trending_news = action.payload.trending_news
    },
    setInfiniteLoading: (state, action) => {
      state.infinteLoading = action.payload.infinteLoading
    },
    setCount: (state, action) => {
      state.elements_count = action.payload.elements_count
    }
  }
})

export const { setLoading, setNews, setNewsDetails, setUpcomingNews, setInfiniteLoading, setCount } = news.actions
export default news.reducer

export const fetchNews = ({ data, callback }) => async (dispatch) => {
  try {
    setSearch('news_Search', {
      search_key: data?.search_key,
      on_date_filter: data?.on_date_filter
    })
    const { getState } = reduxStore
    data?.page === 1 ? dispatch(setLoading({ loading: true })) : dispatch(setInfiniteLoading({ infinteLoading: true }))

    const response = await api.post(API_NEWS, data)
    if (response) {
      data?.page === 1 ? dispatch(setNews({ news: response.data?.news })) : dispatch(setNews({ news: [...getState()?.news?.news, ...response.data?.news] }))
      data?.on_date_filter || data?.search_key ? dispatch(setUpcomingNews({ trending_news: [] })) : dispatch(setUpcomingNews({ trending_news: response?.data?.trending_news }))
      dispatch(setCount({ elements_count: response?.data?.elements_count }))
      data?.page === 1 ? dispatch(setLoading({ loading: false })) : dispatch(setInfiniteLoading({ infinteLoading: false }))
      callback && callback()
      setResponse('news', JSON.stringify(getState()?.news))
      setResponse('newslang', JSON.stringify(getLocalStore('i18nextLng')))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setInfiniteLoading({ infinteLoading: false }))
  }
}

export const fetchNewsDetails = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_NEWS_DETAILS, data)
    if (response) {
      dispatch(setNewsDetails({ newsDetails: response.data?.news_detail }))
      dispatch(setLoading({ loading: false }))
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const clearStoreNews = () => async (dispatch) => {
  dispatch(setNewsDetails({ newsDetails: {} }))
}
