/* global localStorage */

import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'

export const setLocalStore = (cname, cvalue) => {
  if (cvalue) {
    const encryptedData = AES.encrypt(JSON.stringify(cvalue), '123')
    localStorage.setItem(cname, encryptedData)
  }
}

export const setResponse = (cname, cvalue) => {
  if (cvalue) {
    localStorage.setItem(cname, cvalue)
  }
}

export const getResponse = (cname) => {
  return JSON.parse(localStorage.getItem(cname))
}

export const getLocalStore = (cname) => {
  if (cname === 'i18nextLng' || cname === 'eventlang' || cname === 'alertlang' || cname === 'newslang' || cname === 'articlelang' || cname === 'faqlang') return localStorage.getItem(cname)
  let decryptedData = null
  const bytes =
    localStorage.getItem(cname) &&
    AES.decrypt(localStorage.getItem(cname), '123')
  decryptedData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

export const clearLocalStore = () => {
  const lang = localStorage.getItem('lang')
  localStorage.clear()
  localStorage.setItem('lang', lang)
}

export const objectArrayFromField = (list, field, value) => {
  return list?.length
    ? list.map((item) => {
        return {
          label: item[field],
          value: item[value]
        }
      })
    : []
}

export const deleteItem = (cname) => {
  localStorage.removeItem(cname)
}

export const setSearch = (cname, cvalue) => {
  if (cvalue) {
    localStorage.setItem(cname, JSON.stringify(cvalue))
  }
}

export const getData = (key, storeData, targetStorage) => {
  const persistedData = getResponse(targetStorage)
  const formattedData = persistedData || {}
  if (storeData && storeData[key] && storeData[key].length) {
    return storeData[key]
  }
  if (formattedData && formattedData[key] && formattedData[key].length) {
    return formattedData[key]
  }
  return []
}

export const infiniteScrollCheck = () => {
  if (navigator.vendor.includes('Apple')) {
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
      return true
    }
  } else {
    if ((window.innerHeight + Math.ceil(window.pageYOffset + 1)) >= document.body.offsetHeight) {
      return true
    }
  }
}

export const checkForIOS = () => {
  const ua = window.navigator.userAgent
  const webkit = !!ua.match(/WebKit/i)
  const isIPad = !!ua.match(/iPad/i)
  const isIPhone = !!ua.match(/iPhone/i)
  const isIPad13 = !!ua.match(/Mac/i) && window.navigator.maxTouchPoints > 1
  const isIOS = isIPad || isIPhone || isIPad13
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i)
  return isSafari
}
