import styled from 'styled-components'

export const Container = styled.div`
${({ i18n, theme }) => `
background-image: ${theme.background.forgot};

.centerWrapper {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .homeButton {
    background-color: ${theme.colors.white};
    border-radius: 25px;
    border:  ${theme.border.buttonNotFound};
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.primaryMain};
    width: ${i18n.language === 'en' ? '140px' : '240px'};
    height: 40px;
    font-weight: bold;
  }
}
  .MuiButton-root:hover {
    background: ${theme.colors.white} !important;
  }
  `};
`

export const Heading = styled.div`
${({ theme }) => `
display:flex;
justify-content: center;
span {
    font-size: 130px;
    color:${theme.colors.primaryMain};
}
.endFour {
  margin-left: -12px;
  z-index: -99;
}
.image {
  margin-top: 5px;
  margin-left: -10px;
}
`}
`

export const Message = styled.p`
${({ theme }) => `
font-size: ${theme.fontSizes.medium};
margin-top: 10px;
margin-bottom: 55px;
`}`
export const HeaderWrap = styled.div`
display:flex;
position:relative;
height: 85px;
.MuiSvgIcon-root {
  position: absolute;
  top: 23px;
  fill: white;
  left: 18px;
}
#menuIcon {
  height:16px;
  width:22px;
  position:absolute;
  left:0;
  padding-left: 20px;
  top: 38%;
}
`
export const BottomWrapper = styled.div`
${({ theme }) => `
display: flex;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
background-color: ${theme.colors.white};
min-height: calc(100vh - 200px);
`}`
