import styled from 'styled-components'

export const NoNetWrapperData = styled.div`
${({ theme }) => `
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  .head {
    color: ${theme.colors.white};
    font-weight: bold;
    padding-bottom: 25px;
    font-size: 22px;
  }
  .content {
    color: ${theme.colors.white};
    font-size: 14px;
    padding-bottom: 30px;
  }
  .MuiSvgIcon-root {
    height: 40px;
    width: 40px;
    color: ${theme.colors.white};
    padding-bottom: 10px;
  }
  `}`

export const ButtonWrapper = styled.div`
${({ theme }) => `
text-align:center;
.MuiButton-root {
  height: 35px !important;
  width: 100px !important;
  font-size: 14px !important;
  color: ${theme.colors.white} !important;
  background: ${theme.colors.blue} !important;
  padding: 0 !important;
  .MuiButton-label{
    font-weight: bold !important;
  }
}
`}`

export const NoNetWrapper = styled.main`
${({ theme }) => `
background-image: ${theme.background.main};
height: 100vh;
#bgContainer {
  position: absolute;
  right: 20px;
  top: 9%;
}
touch-action: none;
`}`
