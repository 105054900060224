import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Styled from './style'
import ReactSelect, { components } from 'react-select'
import theme from '../../../config/theme'

const { ValueContainer, Placeholder } = components
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  )
}

const Select = (props) => {
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      cursor: 'pointer',
      borderRadius: 12,
      '&:active': {
        border: theme.border.selectControl
      },
      border: theme.border.inputFilled,
      height: 55,
      minHeight: 55,
      boxShadow: 'none'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: 0,
        cursor: 'pointer',
        width: '100%',
        overflow: 'scroll'
      }
    },
    menu: (base) => ({
      ...base,
      zIndex: 100
    }),
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        cursor: 'pointer',
        fontWeight: 'bold',
        top: '75%'
      }
    },
    container: (provided, state) => ({
      ...provided
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: 'visible',
      top: 3
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? '5px' : '35%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 11
    })
  }
  const colourStylesCountry = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      cursor: 'pointer',
      borderRadius: 18,
      border: theme.border.countrySelect,
      fontSize: 16,
      height: 38,
      minHeight: 30,
      boxShadow: '0 0 0 0',
      '&:hover': {
        border: theme.border.countrySelect
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: 0,
        cursor: 'pointer',
        width: '100%',
        overflow: 'hidden',
        fontSize: 13,
        zIndex: 999
      }
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        cursor: 'pointer',
        fontWeight: 'bold'
      }
    },
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: 'visible'
    })
  }
  const [value, setValue] = useState(props.value)
  useEffect(() => {
    if (props.value) {
      setValue(
        typeof props.value === 'object'
          ? props.value
          : props.options.filter((item) => item.value === props.value)[0]
      )
    }
  }, [props.value])
  useEffect(() => {
    props.setClearSelect === 'clear' && setValue(null)
  }, [props.setClearSelect])
  const {
    placeholder,
    updateValue,
    countrySelector,
    defaultValue,
    disabled
  } = props
  const handleOnChange = (e) => {
    const value = updateValue ? updateValue(e.value) : e.value
    props.handleOnChange && props.handleOnChange(value, e)
  }
  return (
    <Styled.MainWrapper countrySelector={countrySelector}>
      {countrySelector
        ? (
          <ReactSelect
            options={props?.options}
            placeholder={placeholder}
            styles={colourStylesCountry}
            onChange={handleOnChange}
            defaultValue={defaultValue || ''}
            className='basic-multi-select'
            classNamePrefix='select'
            isSearchable={false}
            menuPlacement='auto'
          />
          )
        : (
          <ReactSelect
            options={props?.options}
            components={{
              ValueContainer: CustomValueContainer
            }}
            placeholder={placeholder}
            styles={colourStyles}
            onChange={handleOnChange}
            className='basic-multi-select'
            classNamePrefix='select'
            isDisabled={disabled}
            value={value}
            isSearchable={false}
            menuPlacement='auto'
            // menuIsOpen={true}
          />
          )}
    </Styled.MainWrapper>
  )
}

Select.propTypes = {
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func
}

Select.defaultProps = {
  placeholder: '',
  handleOnChange: () => {}
}
export default Select
