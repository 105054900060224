/* global localStorage */
import { createSlice } from '@reduxjs/toolkit'

import api, {
  API_FORGOT_PASSWORD,
  API_REGISTER,
  API_MEMBERSHIP_CATEGORY,
  API_RESET_PASSWORD,
  API_CHANGE_PASSWORD,
  API_VIEW_PROFILE,
  API_UPDATE_PROFILE,
  API_DELETE_ACCOUNT,
  API_POSITION_GROUP,
  API_POSITION_PARLIAMENT,
  API_GEO_GROUPS
} from '../utils/axios'

import { profilePic } from './session.slice'

const initialState = {
  loading: false,
  category: [],
  status: null,
  profilePic: '',
  groups: [],
  parliaments: [],
  geo_groups: []
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setCategory: (state, action) => {
      state.category = action.payload.category
    },
    setStatus: (state, action) => {
      state.status = action.payload.status
    },
    setProfilePic: (state, action) => {
      state.profilePic = action.payload.profilePic
    },
    setUser: (state, action) => {
      state.user = action.payload.user
    },
    setGroups: (state, action) => {
      state.groups = action.payload.groups
    },
    setParliaments: (state, action) => {
      state.parliaments = action.payload.parliaments
    },
    setGeoGroups: (state, action) => {
      state.geo_groups = action.payload.geo_groups
    }
  }
})

export const { setLoading, setUser, setCategory, setStatus, setProfilePic, setGroups, setParliaments, setGeoGroups } = user.actions
export default user.reducer

export const forgotPassword = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_FORGOT_PASSWORD, data)

    if (response) {
      dispatch(setLoading({ loading: false }))
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const register = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_REGISTER, data)
    response && dispatch(setStatus({ status: true }))
    response && dispatch(setLoading({ loading: false }))
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setStatus({ status: false }))
  }
}

export const getMembershipCategory = () => async (dispatch) => {
  try {
    const response = await api.get(API_MEMBERSHIP_CATEGORY, {})
    response && dispatch(setCategory({ category: response.data }))
  } catch (error) { }
}

export const resetPassword = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_RESET_PASSWORD, data)

    if (response) {
      dispatch(setLoading({ loading: false }))
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    callback && callback()
  }
}

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(API_VIEW_PROFILE, {})
    if (response) {
      dispatch(setUser({ user: response.data }))
      dispatch(setLoading({ loading: false }))
      dispatch(profilePic())
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const updateUserProfile = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_UPDATE_PROFILE, data)
    callback && callback()

    if (response) {
      dispatch(setLoading({ loading: false }))
      dispatch(getUserProfile())
      dispatch(profilePic())
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const changeUserPassword = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_CHANGE_PASSWORD, data)
    callback && callback()

    response && dispatch(setLoading({ loading: false }))
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const deleteAccount = () => async (dispatch) => {
  try {
    const response = await api.get(API_DELETE_ACCOUNT, {})
    if (response) {
      localStorage.clear()
      setTimeout(function () {
        window.location.href = '/'
      }, 5000)
    }
  } catch (error) { }
}

export const getPositioninGroup = () => async (dispatch) => {
  try {
    const response = await api.get(API_POSITION_GROUP, {})
    response && dispatch(setGroups({ groups: response?.data?.position_in_group }))
  } catch (error) { }
}

export const getPositioninParliament = () => async (dispatch) => {
  try {
    const response = await api.get(API_POSITION_PARLIAMENT, {})
    response && dispatch(setParliaments({ parliaments: response?.data?.positions_in_parliament }))
  } catch (error) { }
}

export const getGeoGroups = () => async (dispatch) => {
  try {
    const response = await api.get(API_GEO_GROUPS, {})
    response && dispatch(setGeoGroups({ geo_groups: response?.data?.geopolitical_groups }))
  } catch (error) { }
}
