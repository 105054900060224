import React from 'react'
import { useTranslation } from 'react-i18next'

import Select from '../../components/UI/Select'

import useLocalStorage from '../../hooks/useLocalStorage'

import languageBlack from '../../assets/lang.svg'

import { LanguageImage } from './style'

export default function LanguageSwitch ({ setLangChange, onChange }) {
  const [lang, setLang] = useLocalStorage('lang', 'en')

  const category = [{
    value: 'en',
    label: <div><LanguageImage src={languageBlack} />EN</div>
  }, {
    value: 'fr',
    label: <div><LanguageImage src={languageBlack} />FR</div>
  }]
  const { i18n } = useTranslation()
  const changeLanguage = lng => {
    setLangChange && setLangChange(lng)
    setLang(lng)
    i18n.changeLanguage(lng)
  }

  return (
    <Select
      handleOnChange={(event) => {
        changeLanguage(event)
        onChange && onChange()
      }}
      name='category'
      options={category}
      countrySelector
      defaultValue={lang === 'en' ? category[0] : category[1]}
    />
  )
}
