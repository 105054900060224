import { createSlice } from '@reduxjs/toolkit'
import reduxStore from '../store'

import api, { API_FETCH_FAQ } from '../utils/axios'
import { setResponse, getLocalStore } from '../utils/utils'

const initialState = {
  loading: false,
  faq: []
}

const session = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setFaq: (state, action) => {
      state.faq = action.payload.faq
    }
  }
})

export const { setLoading, setFaq, setProfilePic } = session.actions
export default session.reducer

export const fetchFaq = () => async (dispatch) => {
  try {
    const { getState } = reduxStore
    dispatch(setLoading({ loading: true }))
    const response = await api.get(API_FETCH_FAQ, {})
    if (response) {
      const faqArray = response.data.faq

      faqArray.forEach((v) => {
        v.id = Math.random().toString(36).substr(2, 9)
      })
      dispatch(setFaq({ faq: faqArray }))
      dispatch(setLoading({ loading: false }))
      setResponse('faq', JSON.stringify(getState()?.faq))
      setResponse('faqlang', JSON.stringify(getLocalStore('i18nextLng')))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}
