/* global localStorage */

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import { Wrapper, ContentWrapper, TopWrapper, MenuWrapper, AboutWrapper } from './style'
import { useTranslation } from 'react-i18next'
import LanguageSwitch from '../../components/LanguageSwitch'
import Avatar from '@material-ui/core/Avatar'
import hamburgerMenu from '../../config/menu/hamburgerMenu'
import { getLocalStore } from '../../utils/utils'
import { logout } from '../../slices/session.slice'
import { Loader } from '../../components/CustomLoader'

const HamburgerMenu = ({ isOpen, closeDrawer, openDrawer, setLangChange }) => {
  const data = useSelector((state) => state.session)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const profilePic = getLocalStore('PROFILE_IMAGE')
  const firebaseToken = localStorage.getItem('firebaseToken')
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  const logoutFn = () => {
    const lang = window.localStorage.getItem('lang')
    window.localStorage.clear()
    window.localStorage.setItem('lang', lang)
    window.localStorage.setItem('firebaseToken', firebaseToken)
    closeDrawer()
    history.push('/')
    const data = {
      token: firebaseToken,
      uid: auth?.current_user?.uid
    }
    firebaseToken && dispatch(logout({ data: data }))
  }
  return (
    <Wrapper>
      <SwipeableDrawer
        anchor='left'
        open={isOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <ContentWrapper>
          {data?.logoutLoader && <Loader />}
          <TopWrapper profilePic={profilePic}>
            <Avatar
              alt='Avatar'
              src={profilePic}
              onClick={() => {
                history.push('/profile')
                closeDrawer()
              }}
            />
            <span
              className='name'
              onClick={() => {
                history.push('/profile')
                closeDrawer()
              }}
            >
              {getLocalStore('PROFILE_DETAILS')?.name}
            </span>
            <div className='langSw'>
              <LanguageSwitch onChange={() => closeDrawer()} setLangChange={setLangChange} />
            </div>
          </TopWrapper>
          <MenuWrapper>
            <AboutWrapper href='/'>{t('hamburger.about')}</AboutWrapper>
            {hamburgerMenu?.map((items, index) => {
              return (
                <span
                  onClick={() => {
                    if ((items.api) === 'logout') {
                      logoutFn()
                    } else {
                      const name = items?.api
                      history.push(items.route, { name })
                      closeDrawer()
                    }
                  }}
                  key={index}
                >
                  {t(items.label)}
                </span>
              )
            })}
          </MenuWrapper>
        </ContentWrapper>
      </SwipeableDrawer>
    </Wrapper>
  )
}

export default HamburgerMenu
