import React from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import {
  Wrapper,
  SideBarHeader,
  HeaderImage,
  SideBarBody,
  SideBarFooter,
  RegisterButton,
  LoginButton,
  CountrySelect
} from './style'
import LanguageSwitch from '../../components/LanguageSwitch'

import HeaderLogo from '../../assets/hamburger_header.webp'

const HamburgerMenu = ({
  isOpen,
  closeDrawer,
  openDrawer,
  setLangChange,
  t
}) => {
  const getYear = () => {
    const d = new Date()
    return d.getFullYear()
  }
  return (
    <Wrapper>
      <SwipeableDrawer
        anchor='left'
        open={isOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <SideBarHeader>
          <HeaderImage src={HeaderLogo} />
        </SideBarHeader>
        <CountrySelect>
          <div className='langSw'>
            <LanguageSwitch
              onChange={() => closeDrawer()}
              setLangChange={setLangChange}
            />
          </div>
        </CountrySelect>
        <SideBarBody>
          <LoginButton>
            <a href='/login'>{t('login.heading')}</a>
          </LoginButton>
          <RegisterButton>
            <a href='/signup'>{t('register.heading')}</a>
          </RegisterButton>
        </SideBarBody>
        <SideBarFooter>© {t('Copyright')} {getYear()}</SideBarFooter>
      </SwipeableDrawer>
    </Wrapper>
  )
}

export default HamburgerMenu
