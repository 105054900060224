import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledContainer } from './styled'

const Loader = ({ open }) =>
  open
    ? (
      <StyledContainer>
        <CircularProgress />
      </StyledContainer>)
    : null

Loader.defaultProps = {
  open: true
}

export { Loader }
