import styled from 'styled-components'
import Box from '@material-ui/core/Box'

const Wrapper = styled.div`
`
const Main = styled.main`
`
const Content = styled(Box)`
min-height: calc(100vh - 80px);
overflow: auto;
`
const FooterWrapper = styled(Box)`
height:80px;
`
const NoNetWrapper = styled.main`
${({ theme }) => `
background-image: ${theme.background.main};
height: 100vh;
#bgContainer {
  position: absolute;
  right: 20px;
  top: 9%;
}
touch-action: none;
`}`

const NoNetWrapperData = styled.div`
${({ theme }) => `
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  .head {
    color: ${theme.colors.white};
    font-weight: bold;
    padding-bottom: 25px;
    font-size: 22px;
  }
  .content {
    color: ${theme.colors.white};
    font-size: 14px;
    padding-bottom: 30px;
  }
  .MuiSvgIcon-root {
    height: 40px;
    width: 40px;
    color: white;
    padding-bottom: 10px;
  }
  `}`

const ButtonWrapper = styled.div`
${({ theme }) => `
text-align:center;
.MuiButton-root {
  height: 35px;
  width: 100px;
  font-size: 14px;
  color:${theme.colors.blue};
  background: ${theme.colors.white};
  padding: 0;
  .MuiButton-label{
    font-weight: bold;
  }
}
.MuiButton-root:hover {
  background-color: ${theme.colors.white} !important;
}
`}`

const Styled = {
  Wrapper,
  Main,
  Content,
  FooterWrapper,
  NoNetWrapper,
  NoNetWrapperData,
  ButtonWrapper
}

export default Styled
