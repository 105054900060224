import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Wrapper } from './style'
import { useTranslation } from 'react-i18next'
import filterImg from '../../assets/filter.png'
import SearchImg from '../../assets/search.png'
import MenuImg from '../../assets/menu.png'
import Drawer from '../../components/HamburgerMenu'
import filterSelImg from '../../assets/filter-selected.png'
import footerMenu from '../../config/menu/footer'
import { getResponse } from '../../utils/utils'
import BackImg from '../../assets/back.png'
import { clearStoreEvent } from '../../slices/events.slice'
import {
  clearHlagUserDetails
} from '../../slices/hlag.slice'
import { clearStore } from '../../slices/articles.slice'
import { clearStoreNews } from '../../slices/news.slice'
import { clearStoreStatic } from '../../slices/static.slice'
import { clearStoreMsg } from '../../slices/message.slice'
const Header = ({ openSearch, setLangChange, headerImage }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [value, setValue] = useState(0)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [calenderOpen, setCalenderOpen] = useState(false)
  const [noFilterIcon, setnoFilterIcon] = useState(false)
  const [noSearchMenuIcon, setnoSearchMenuIcon] = useState(false)
  const [noHeader, setnoHeader] = useState(false)
  const closeDrawer = () => {
    setIsOpen(!isOpen)
  }

  const openDrawer = () => {
    if (noSearchMenuIcon && location.includes('eventsdetails')) {
      dispatch(clearStoreEvent())
      if (history?.location?.state?.referrer === 'alerts') {
        history.push('/alerts')
      } else {
        history.push('/events')
      }
    } else if (noSearchMenuIcon && location.includes('articlesdetails')) {
      dispatch(clearStore())
      if (history?.location?.state?.referrer === 'alerts') {
        history.push('/alerts')
      } else {
        history.push('/articles')
      }
    } else if (noSearchMenuIcon && location.includes('newsdetails')) {
      dispatch(clearStoreNews())
      if (history?.location?.state?.referrer === 'alerts') {
        history.push('/alerts')
      } else {
        history.push('/news')
      }
    } else if (noSearchMenuIcon && location.includes('messagedetails')) {
      dispatch(clearStoreMsg())
      history.push('/alerts')
    } else if (noSearchMenuIcon && location.includes('individualUser')) {
      dispatch(clearHlagUserDetails())
      window.history.back()
    } else if (noSearchMenuIcon) {
      dispatch(clearStoreStatic())
      const name = 'fromMenu'
      history.push('/faq', { name })
    } else {
      setIsOpen(!isOpen)
    }
  }
  const noFilter = ['alerts', 'faq', 'profile', 'eventsdetails', 'individualUser', 'newsdetails', 'articlesdetails', 'static', 'messagedetails']
  const noHeaderPages = ['hlag']
  const location = window.location.href
  const total = location.split('/')
  const lastEle = total[total.length - 1]
  const secLastEle = total[total.length - 2]
  const noSearch = ['profile', 'eventsdetails', 'individualUser', 'newsdetails', 'articlesdetails', 'static', 'messagedetails']
  useEffect(() => {
    openSearch(calenderOpen)
  }, [calenderOpen])

  useEffect(() => {
    const index = footerMenu.findIndex((ele) => {
      return ele.route.includes(lastEle)
    })
    setValue(index)

    if (noFilter.includes(location.includes('details') ? secLastEle : lastEle)) {
      setnoFilterIcon(true)
    } else {
      setnoFilterIcon(false)
    }

    if (noHeaderPages.some(v => location.includes(v))) {
      setnoHeader(true)
    } else {
      setnoHeader(false)
    }

    if (noSearch.includes(location.includes('details') ? secLastEle : lastEle)) {
      setnoSearchMenuIcon(true)
    } else {
      setnoSearchMenuIcon(false)
    }
  })

  useEffect(() => {
    if (history?.location?.pathname?.includes('news')) {
      if (getResponse('news_Search')?.search_key || getResponse('news_Search')?.on_date_filter) {
        openSearch(true)
        setCalenderOpen(true)
      } else {
        openSearch(false)
        setCalenderOpen(false)
      }
    } else if (history?.location?.pathname?.includes('events')) {
      if (getResponse('events_Search')?.search_key || getResponse('events_Search')?.on_date_filter) {
        openSearch(true)
        setCalenderOpen(true)
      } else {
        openSearch(false)
        setCalenderOpen(false)
      }
    } else if (history?.location?.pathname?.includes('articles')) {
      if (getResponse('articles_Search')?.search_key || getResponse('articles_Search')?.on_date_filter) {
        openSearch(true)
        setCalenderOpen(true)
      } else {
        openSearch(false)
        setCalenderOpen(false)
      }
    } else if (history?.location?.pathname?.includes('alerts')) {
      if (getResponse('alerts_Search')?.search_key) {
        openSearch(true)
        setCalenderOpen(true)
      } else {
        openSearch(false)
        setCalenderOpen(false)
      }
    } else {
      openSearch(false)
      setCalenderOpen(false)
    }
  }, [history.location.pathname])
  const { state: historyState } = history.location
  useEffect(() => {
    if (historyState?.name === 'fromMenu') {
      setIsOpen(!isOpen)
    }
  }, [historyState?.name])
  return (
    <Wrapper noFilterIcon={noFilterIcon} noHeader={noHeader} noSearchMenuIcon={noSearchMenuIcon} headerImage={headerImage}>
      <img src={noSearchMenuIcon ? BackImg : MenuImg} id={noSearchMenuIcon ? 'backIcon' : 'menuIcon'} onClick={() => openDrawer()} />
      <h2>{footerMenu[value] && t(footerMenu[value].label)}</h2>
      {!noSearchMenuIcon && <img src={SearchImg} id='searchIcon' onClick={() => setCalenderOpen(!calenderOpen)} />}
      {!noFilterIcon && <img src={calenderOpen ? filterSelImg : filterImg} id='filterIcon' onClick={() => setCalenderOpen(!calenderOpen)} />}
      <Drawer
        isOpen={isOpen}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        setLangChange={setLangChange}
      />
    </Wrapper>
  )
}

export default Header
