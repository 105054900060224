import { createSlice } from '@reduxjs/toolkit'
import reduxStore from '../store'

import api, { API_ARTICLES, API_ARTICLES_DETAILS } from '../utils/axios'
import { setResponse, getLocalStore, setSearch } from '../utils/utils'

const initialState = {
  loading: false,
  articleDetails: {},
  articles: [],
  trending_articles: [],
  infinteLoading: false,
  elements_count: 0
}

const articles = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setArticles: (state, action) => {
      state.articles = action.payload.articles
    },
    setArticlesDetails: (state, action) => {
      state.articleDetails = action.payload.articleDetails
    },
    setTrending: (state, action) => {
      state.trending_articles = action.payload.trending_articles
    },
    setInfiniteLoading: (state, action) => {
      state.infinteLoading = action.payload.infinteLoading
    },
    setCount: (state, action) => {
      state.elements_count = action.payload.elements_count
    }
  }
})

export const { setLoading, setArticles, setArticlesDetails, setTrending, setInfiniteLoading, setCount } = articles.actions
export default articles.reducer

export const fetchArticles = ({ data, callback }) => async (dispatch) => {
  try {
    setSearch('articles_Search', {
      search_key: data?.search_key,
      on_date_filter: data?.on_date_filter
    })
    const { getState } = reduxStore
    data?.page === 1 ? dispatch(setLoading({ loading: true })) : dispatch(setInfiniteLoading({ infinteLoading: true }))

    const response = await api.post(API_ARTICLES, data)
    if (response) {
      data?.page === 1 ? dispatch(setArticles({ articles: response.data?.articles })) : dispatch(setArticles({ articles: [...getState()?.articles?.articles, ...response.data?.articles] }))
      data?.on_date_filter || data?.search_key ? dispatch(setTrending({ trending_articles: [] })) : dispatch(setTrending({ trending_articles: response?.data?.trending_articles }))
      dispatch(setCount({ elements_count: response?.data?.elements_count }))
      data?.page === 1 ? dispatch(setLoading({ loading: false })) : dispatch(setInfiniteLoading({ infinteLoading: false }))
      callback && callback()
      setResponse('articles', JSON.stringify(getState()?.articles))
      setResponse('articlelang', JSON.stringify(getLocalStore('i18nextLng')))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setInfiniteLoading({ infinteLoading: false }))
  }
}

export const fetchArticleDetails = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))

    const response = await api.post(API_ARTICLES_DETAILS, data)
    if (response) {
      dispatch(setArticlesDetails({ articleDetails: response.data?.article_detail }))
      dispatch(setLoading({ loading: false }))
      callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const clearStore = () => async (dispatch) => {
  dispatch(setArticlesDetails({ articleDetails: {} }))
}
