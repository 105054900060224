import { createSlice } from '@reduxjs/toolkit'

import api, { LANDING_PAGE, LANDING_LOGGED_PAGE } from '../utils/axios'
import { getLocalStore } from '../utils/utils'

const initialState = {
  loading: true,
  landingPage: []
}

const session = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setLandingData: (state, action) => {
      state.landingPage = action.payload.data
    }
  }
})

export const { setLoading, setLandingData } = session.actions
export default session.reducer

export const fetchLandingPage = () => async (dispatch) => {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(auth ? LANDING_LOGGED_PAGE : LANDING_PAGE, {})
    if (response) {
      dispatch(setLandingData({ data: response.data.page_sections }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}
