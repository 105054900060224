import { Component } from 'react'
import { I18nextProvider } from 'react-i18next'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { NoNetWrapperData, ButtonWrapper, NoNetWrapper } from './style'
import i18n from '../../config/locales/i18n'
import Button from '../UI/Button'
import pattern from '../../assets/pattern.png'
import store from '../../store'
import { serverUnavailableCodes } from '../../utils/utils/constants'
import { getLocalStore } from '../../utils/utils'
export default class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: false
    }

    store.subscribe(() => {
      if (getLocalStore('AUTH_DETAILS_MOBILE') && serverUnavailableCodes.includes(store.getState().snackbar.status)) {
        this.setState({
          hasError: true
        })
      }
    })
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  reloadPage () {
    if (window.navigator.onLine) {
      window.location.reload()
    }
  }

  render () {
    if (this.state.hasError) {
      return (
        <I18nextProvider i18n={i18n}>
          <NoNetWrapper>
            <img src={pattern} id='bgContainer' />
            <NoNetWrapperData>
              <ErrorOutlineIcon />
              <div className='head'>
                {i18n.t('app.wrong')}
              </div>
              <div className='content'>
                {i18n.t('app.someWrong')}
              </div>
              <ButtonWrapper>
                <Button
                  onClick={() => this.reloadPage()}
                  label={i18n.t('app.retry')}
                  className='someWrong'
                />
              </ButtonWrapper>
            </NoNetWrapperData>
          </NoNetWrapper>
        </I18nextProvider>
      )
    }

    return this.props.children
  }
}
