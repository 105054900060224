import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Header from './Header'
import Footer from '../../components/Footer'

import { getLocalStore } from '../../utils/utils'
import { fetchLandingPage } from '../../slices/landingPage.slice'
import { Loader } from '../../components/CustomLoader'

import {
  Container,
  FooterWrapper,
  SectionParaBox,
  SectionPara,
  AboutIPUImg,
  ContentBox,
  SectionTitle,
  SectionLine
} from './style'

export default function LandingPage () {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  const dispatch = useDispatch()
  const [firstSection, setFirstSection] = useState({})
  const [secondSection, setSecondSection] = useState({})

  const data = useSelector((state) => state.landingPage)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    const orderOne = data.landingPage.filter((items) => items.page_section_order === '1')
    setFirstSection(orderOne[0] || {})
    const orderTwo = data.landingPage.filter((items) => items.page_section_order === '2')
    setSecondSection(orderTwo[0] || {})
  }, [data])

  useEffect(() => {
    dispatch(fetchLandingPage())
  }, [i18n.language])

  function createMarkup (content) {
    return {
      __html: content
    }
  }

  return (
    <Container>
      {data?.loading &&
        <Loader />}
      <Header title={firstSection?.title || t('hamburger.about')} />
      {Object.keys(firstSection).length > 0 &&
        <SectionParaBox>
          <AboutIPUImg src={`${process.env.REACT_APP_BACKEND_URL}${firstSection.image_uri}`} />
          <SectionPara>
            <span
              dangerouslySetInnerHTML={createMarkup(
                firstSection.description
              )}
            />
          </SectionPara>
        </SectionParaBox>}
      {Object.keys(secondSection).length > 0 &&
        <ContentBox>
          <SectionTitle>{secondSection.title}</SectionTitle>
          <SectionLine />
          <SectionPara className='secondSection'>
            <span
              dangerouslySetInnerHTML={createMarkup(
                secondSection.description
              )}
            />
            <AboutIPUImg src={`${process.env.REACT_APP_BACKEND_URL}${secondSection.image_uri}`} />
          </SectionPara>
        </ContentBox>}
      <FooterWrapper>{auth && <Footer />}</FooterWrapper>
    </Container>
  )
}
