import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ButtonContainer from '../Button/style'

const Button = ({
  size,
  id,
  key,
  className,
  type,
  onClick,
  label,
  variant,
  isLoading,
  startIcon
}) => {
  const handleOnClick = (e) => {
    onClick(e)
  }
  const idGenerator = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1))
  }

  return (
    <ButtonContainer>
      <ButtonComponent
        type={type || 'button'}
        size={size || 'small'}
        variant={variant || 'outlined'}
        disableripple='true'
        onClick={handleOnClick}
        id={id || `button_${idGenerator(5000, 1)}`}
        key={key}
        className={type === 'reset' ? 'reset' : className}
        disabled={isLoading}
        startIcon={startIcon}
      >
        {!isLoading && label}
        {isLoading && <CircularProgress className='' size={30} />}
      </ButtonComponent>
    </ButtonContainer>
  )
}

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
  dataValue: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
}

Button.defaultProps = {
  size: '',
  id: '',
  key: '',
  className: '',
  type: '',
  label: '',
  onClick: () => { }
}

export default Button
