import { createSlice } from '@reduxjs/toolkit'

import api, { STATIC_SCREEN } from '../utils/axios'

const initialState = {
  loading: false,
  staticDetails: {},
  notFound: false
}

const staticScreen = createSlice({
  name: 'staticScreen',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setStaticDetails: (state, action) => {
      state.staticDetails = action.payload.staticDetails
    }
  }
})

export const { setLoading, setStaticDetails } = staticScreen.actions
export default staticScreen.reducer

export const fetchLegDetails = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(STATIC_SCREEN.replace('{api}', data?.name))
    if (response) {
      dispatch(setStaticDetails({ staticDetails: response.data?.static_content }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      data?.history.push('/notFound')
    }
    dispatch(setLoading({ loading: false }))
  }
}

export const clearStoreStatic = () => async (dispatch) => {
  dispatch(setStaticDetails({ staticDetails: {} }))
}
