import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getLocalStore } from './utils/utils'

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  return (
    <Route
      {...rest} render={props => (
        auth && rest.path !== '/'
          ? <Redirect to='/faq' />
          : <Component {...props} />
      )}
    />
  )
}

export default PublicRoute
