import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Badge from '@material-ui/core/Badge'

import { Wrapper } from './style'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import footerMenu from '../../config/menu/footer'
import { clearStore } from '../../slices/articles.slice'
import { clearStoreEvent } from '../../slices/events.slice'
import { clearStoreMsg } from '../../slices/message.slice'
import { clearStoreNews } from '../../slices/news.slice'
import { clearStoreStatic } from '../../slices/static.slice'

const Footer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const [value, setValue] = useState(0)
  const data = useSelector((state) => state.session)
  const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '80px',
      position: 'fixed',
      bottom: 0,
      '& .MuiBottomNavigationAction-root': {
        '@media (max-width: 768px)': {
          minWidth: 'auto',
          padding: '6px 0'
        }
      },
      zIndex: 99
    }
  })
  const classes = useStyles()
  useEffect(() => {
    const location = window.location.href
    const total = location.split('/')
    const lastEle = total[total.length - 1]
    const secLastEle = total[total.length - 2]
    let index
    if (location.includes('details')) {
      index = footerMenu.findIndex((ele) => {
        return ele.details.includes(secLastEle)
      })
    } else {
      index = footerMenu.findIndex((ele) => {
        return ele.route.includes(lastEle)
      })
    }
    setValue(index)
  })

  const IconSetFn = (index, value, items) => {
    if (index === value) {
      if (items.label === 'footer.alerts') {
        return (
          <Badge badgeContent={data?.details?.count_unread_alerts} color='error'>
            <img src={items.active} />
          </Badge>
        )
      } else {
        return <img src={window.location.href?.split('/')[3] ? items.active : items.icon} />
      }
    } else {
      if (items.label === 'footer.alerts') {
        return (
          <Badge badgeContent={data?.details?.count_unread_alerts} color='error'>
            <img src={items.icon} />
          </Badge>
        )
      } else {
        return <img src={items.icon} />
      }
    }
  }
  return (
    <Wrapper>
      <BottomNavigation
        value={window.location.href?.split('/')[3] && value}
        onChange={(event, newValue) => {
          dispatch(clearStore())
          dispatch(clearStoreEvent())
          dispatch(clearStoreMsg())
          dispatch(clearStoreNews())
          dispatch(clearStoreStatic())
          setValue(newValue)
          const selItem = footerMenu[newValue]
          const newRoute = selItem.route
          history.push(newRoute)
        }}
        showLabels
        className={classes.root}
      >
        {
          footerMenu.map((items, index) => {
            return (
              <BottomNavigationAction
                label={t(items.label)}
                icon={IconSetFn(index, value, items)}
                key={index}
              />
            )
          })
        }
      </BottomNavigation>
    </Wrapper>
  )
}

export default Footer
